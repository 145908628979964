<template>
  <div class="login-wrap">
    <div class="top">
      <a class="logo" @click="goToHome"/>
      <span>무료 전자계약 솔루션, 유캔싸인</span>
    </div>
    <!-- <ul class="nav nav-tabs">
      <li class="nav-item">
        <a :class="tabClass(true)" @click="()=>{changeTab(true)}">주계정</a>
      </li>
      <li class="nav-item">
        <a :class="tabClass(false)" @click="()=>{changeTab(false)}">부계정</a>
      </li>
    </ul>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a :class="tabClass(true)" @click="()=>{changeTab(true)}">페이앱</a>
      </li>
      <li class="nav-item">
        <a :class="tabClass(false)" @click="()=>{changeTab(false)}">부계정</a>
      </li>
    </ul>
     -->
    <form>
      <div :hidden="isMainAccount === false" class="inputWrap">
        <div class="login-txt">
          유캔싸인은 페이앱 통합계정을 기반으로 서비스가 제공됩니다.<br>
          페이앱 계정을 이용해 간편하게 로그인하세요.
        </div>
        <a-input v-model:value="id" :disabled="isLogining" autocomplete="username" placeholder="아이디를 입력하세요" type="text" @keyup.enter="login"/>
        <a-input v-model:value="pwd" :disabled="isLogining" autocomplete="current-password" placeholder="비밀번호를 입력하세요" type="password" @keyup.enter="login"/>
        <div class="checks">
          <a-checkbox id="saveID" v-model:checked="isSaveId" :disabled="isLogining" style="padding-right: 5px"/>
          <label :disabled="isLogining" for="saveID">아이디 저장</label>
        </div>
      </div>
    </form>
    <!--
    <form>
      <div :hidden="isMainAccount === true" class="inputWrap">
        <a-input v-model:value="subId" :disabled="isLogining" autocomplete="username" placeholder="부계정 아이디를 입력하세요" type="text" @keyup.enter="login"/>
        <a-input v-model:value="subPwd" :disabled="isLogining" autocomplete="current-password" placeholder="비밀번호를 입력하세요" type="password" @keyup.enter="login"/>
        <div class="checks">
          <a-checkbox id="saveSubID" v-model:checked="isSaveId" :disabled="isLogining" style="padding-right: 5px"/>
          <label :disabled="isLogining" for="saveID">아이디 저장</label>
        </div>
      </div>
    </form>
    -->
    <a-button :loading="isLogining" class="btn-b btn-blue" style="height: 60px; font-size: 20px" @click.prevent="login">로그인</a-button>

    <div class="policy">
      <ul>
        <li><a @click="join">회원가입</a></li>
        <li><a @click="findId">아이디 찾기</a></li>
        <li><a @click="findPwd">비밀번호 찾기</a></li>
      </ul>
    </div>
    <div class="copyright">
      Copyright © 2022 <strong>UDID</strong> Inc. All rights reserved.
    </div>
  </div>
  <UcsModal v-model:visible="showEmailAuth" class="emailCk" width="480px">
    <template #content>
      <div class="cont-header">
        <svg aria-hidden="true" class="ico-img" data-icon="exclamation-circle" fill="#1890ff" focusable="false" height="4.5em" viewBox="64 64 896 896" width="4.5em">
          <path
            d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 010-96 48.01 48.01 0 010 96z">
          </path>
        </svg>
        <h3>이메일 인증 안내</h3>
        <p>
          페이앱 계정으로 유캔싸인에 처음 로그인 시 <em>최초 1회 이메일 인증</em>이 필요합니다.<br/>
          아래 입력된 이메일 주소로 <em>인증 메일을 전송하여 인증을 완료해 주세요.</em><br/>
          *인증된 이메일 주소는 유캔싸인에서 서명요청/진행 시 사용되기 때문에<br/>자주 사용하는 이메일로 인증하는 것을 권장드립니다.<br/>
        </p>
      </div>
      <div class="cont-form">
        <a-form>
          <div class="input-wrap">
            <a-form-item>
              <a-input v-model:value="inputEmail" place required @change="inputChange"/>
              <div :class="{ 'txt-rd': !checkEmailDuplicate, 'txt-point': checkEmailDuplicate }">{{ duplicateResultMsg }}</div>
            </a-form-item>
            <a-button @click="checkDuplicateEmail">중복확인</a-button>
          </div>
          <a-button :disabled="!checkEmailDuplicate" @click="requestAuthentication">인증 메일 전송</a-button>
        </a-form>
      </div>
    </template>
  </UcsModal>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { Modal } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { changeEmail as changeEmailApi, checkDuplicateEmail as checkDuplicateEmailApi, getEmailByLoginId, requestValidateEmail as requestAuth } from 'api/user'
import { validEmail } from 'utils/validate'
import UcsModal from './modules/Modal.vue'

export default defineComponent({
  components: {
    UcsModal
  },

  setup (props, context) {
    const store = useStore()
    const savedId = store.state.user.savedId
    const savedMainId = store.state.user.savedMainId
    const savedSubId = store.state.user.savedSubId

    const state = reactive({
      id: savedId,
      pwd: '',
      mainId: savedMainId,
      subId: savedSubId,
      subPwd: '',
      isSaveId: savedId.length > 0,
      isLogining: false,
      isMainAccount: true,
      showEmailAuth: false,
      checkEmailDuplicate: false,
      duplicateResultMsg: '\u00A0',
      originEmail: '',
      inputEmail: ''
    })

    const router = useRouter()

    const goToHome = () => {
      window.location.href = 'https://ucansign.com/'
    }

    const login = sender => {
      state.isLogining = true
      setTimeout(() => {
        var method = ''
        var parameters = {}
        if (state.isMainAccount === true) {
          method = 'login'
          parameters = {
            id: state.id,
            password: state.pwd,
            saveId: state.isSaveId
          }
        } else {
          method = 'sublogin'
          parameters = {
            id: state.subId,
            password: state.subPwd,
            saveId: state.isSaveId
          }
        }
        store.dispatch(method, parameters)
          .then(() => {
            // router.push('/documents')
            router.push({ name: 'Home' }).catch(error => {
              Modal.error({ content: `페이지 이동에 실패했습니다. 에러 로그 : ${error}` })
            })
          })
          .catch(err => {
            if (err.code === 1059) {
              showMailAuthModal()
            } else {
              Modal.error({ content: err.msg ? err.msg : err })
            }
          })
          .finally(() => {
            state.isLogining = false
          })
      }, 1000)
    }

    const showMailAuthModal = () => {
      const id = state.isMainAccount ? state.id : state.subId
      const type = state.isMainAccount ? 'PERSONAL' : 'SUB_ACCOUNT'
      state.originEmail = getEmailByLoginId(id, type)
        .then(res => {
          state.originEmail = res.result.email
          state.inputEmail = state.originEmail
        }).catch(err => {
          Modal.error({ content: err.msg })
        })
      state.showEmailAuth = true
    }

    const checkDuplicateEmail = () => {
      if (!validEmail(state.inputEmail)) {
        state.checkEmailDuplicate = false
        state.duplicateResultMsg = '유효한 이메일을 입력해주세요.'
      } else {
        checkDuplicateEmailApi(state.inputEmail)
          .then(() => {
            state.checkEmailDuplicate = true
            state.duplicateResultMsg = '사용 가능한 이메일입니다.'
          })
          .catch(err => {
            state.checkEmailDuplicate = false
            if (err.code === 1052) {
              state.duplicateResultMsg = '입력하신 이메일은 이미 등록되어 있습니다.'
            } else {
              Modal.error({ content: err.msg })
            }
          })
      }
    }

    const confirmEmailChange = (id, type, email) => {
      Modal.confirm({
        title: '이메일 변경',
        content: '입력하신 이메일은 기존에 저장된 이메일 정보와 다릅니다. 변경하시겠습니까?',
        okText: '변경',
        cancelText: '취소',
        onOk () {
          changeEmailApi(id, type, email)
            .then(() => {
              sendAuthEmail()
            })
            .catch((err) => {
              Modal.error({ content: err.msg })
            })
        },
        onCancel () {
          state.inputEmail = ''
          state.duplicateResultMsg = '\u00A0'
          state.checkEmailDuplicate = false
        }
      })
    }

    const requestAuthentication = () => {
      if (state.originEmail.toLowerCase() !== state.inputEmail.toLowerCase()) {
        const id = state.isMainAccount ? state.id : state.subId
        const type = state.isMainAccount ? 'PERSONAL' : 'SUB_ACCOUNT'
        state.originEmail = state.inputEmail
        confirmEmailChange(id, type, state.inputEmail)
      } else {
        sendAuthEmail()
      }
    }

    const sendAuthEmail = () => {
      const id = state.isMainAccount ? state.id : state.subId
      const type = state.isMainAccount ? 'PERSONAL' : 'SUB_ACCOUNT'
      requestAuth(id, type, state.inputEmail, null, null, null)
        .catch((err) => {
          if (err.code === 1021) {
            Modal.info({ content: err.msg })
          } else {
            Modal.error({ content: err.msg })
          }
        })
    }

    const inputChange = () => {
      state.duplicateResultMsg = '\u00A0'
      state.checkEmailDuplicate = false
    }
    /*
    const push = path => {
      if (state.isLogining) {
        return
      }
      router.push(path)
    }
    */
    const join = () => {
      // push('/user/join')
      window.location.href = 'https://ucansign.com/signup'
    }

    const findId = () => {
      // push('/user/forget#id')
      window.open('https://seller.payapp.kr/a/findId')
    }

    const findPwd = () => {
      // push('/user/forget#password')
      window.open('https://seller.payapp.kr/a/findPwd')
    }

    const tabClass = (isMainAccount) => {
      const active = isMainAccount ? state.isMainAccount : !state.isMainAccount
      return active ? 'nav-link active' : 'nav-link'
    }

    const changeTab = (isMainAccount) => {
      if (state.isMainAccount !== isMainAccount) {
        state.isMainAccount = isMainAccount
      }
    }

    return {
      ...toRefs(state),
      goToHome,
      login,
      join,
      findId,
      findPwd,
      tabClass,
      changeTab,
      requestAuthentication,
      getEmailByLoginId,
      showMailAuthModal,
      requestAuth,
      checkDuplicateEmail,
      sendAuthEmail,
      confirmEmailChange,
      changeEmailApi,
      inputChange
    }
  }
})
</script>

<style lang="less" scoped>
.active {
  color: blue;
}

.error {
  color: red;
}

.login-txt {
  text-align: center;
  margin-top: -4rem;
  font-size: 1.4rem;
  margin-bottom: 2rem;
  background: #e6f7ff;
  color: #1890ff;
  padding: 1.5rem 0
}

@import '../../assets/styles/user.less';
</style>
